var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-img',{attrs:{"height":"100px","contain":"","src":_vm.img}})],1),_c('v-card-text',{staticClass:"d-flex mt-5"},[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text mt-1",attrs:{"size":"54","rounded":"","color":"primary"}},[_c('div',[_c('p',{staticClass:"font-weight-medium mb-0"},[_vm._v(" "+_vm._s(_vm.event.month)+" ")]),_c('p',{staticClass:"text-xl primary--text font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.event.day)+" ")])])]),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-base font-weight-semibold text--primary mb-0"},[_vm._v(" "+_vm._s(_vm.getTitle(_vm.event))+" ")]),_c('span',{staticClass:"text-xs"},[_c('v-tooltip',{attrs:{"color":"primary","max-width":"350","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("truncate")(_vm.getDescripcion(_vm.event),80)))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getDescripcion(_vm.event)))])])],1)])],1),_c('v-card-text',[_c('v-divider')],1),_c('v-card-text',{staticClass:"text-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',[_c('v-btn',{attrs:{"icon":""}},[_c('v-tooltip',{attrs:{"color":"primary","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"30","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-star-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('landing.intrested')))])])],1),_c('span',{staticClass:"text-no-wrap primary--text"},[(_vm.event.cant_intrested > 0)?_c('span',[_c('br'),_vm._v(" "+_vm._s(_vm._f("number")(_vm.event.cant_intrested,'0a'))+" ")]):_vm._e()])],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',[_c('v-btn',{attrs:{"icon":""}},[_c('v-tooltip',{attrs:{"color":"primary","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"30","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('landing.solicitude')))])])],1),_c('span',{staticClass:"text-no-wrap primary--text"},[(_vm.event.cant_invited > 0)?_c('span',[_c('br'),_vm._v(" "+_vm._s(_vm._f("number")(_vm.event.cant_invited,'0a'))+" ")]):_vm._e()])],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',[_c('v-btn',{attrs:{"icon":""}},[_c('v-tooltip',{attrs:{"color":"primary","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"30","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('landing.confirm')))])])],1),_c('span',{staticClass:"text-no-wrap primary--text"},[(_vm.event.cant_confirm > 0)?_c('span',[_c('br'),_vm._v(" "+_vm._s(_vm._f("number")(_vm.event.cant_confirm,'0a'))+" ")]):_vm._e()])],1)])],1)],1),_c('v-card-text',[_c('v-divider')],1),_c('v-card-text',{staticClass:"text-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","block":"","outlined":"","to":("event/" + (_vm.event.slug))}},[_c('span',[_vm._v(_vm._s(_vm.event.seller ? _vm.$t('landing.event.btnReserve') : _vm.$t('landing.event.solicitaBtnCita')))])])],1)],1)],1),_c('v-card-text',[_c('v-divider')],1),_c('v-card-text',[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClockOutline)+" ")]),_c('div',{staticClass:"ms-2"},[_c('p',{staticClass:"text--primary mb-0"},[_vm._v(" "+_vm._s(_vm.$moment(_vm.event.dateFrom).locale(_vm.$i18n.locale).format('dddd, D MMMM'))+" ")])])],1),(_vm.event.country)?_c('div',{staticClass:"d-flex align-center mt-2"},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMapMarkerOutline)+" ")]),_c('div',{staticClass:"ms-2"},[_c('p',{staticClass:"text--primary mb-0"},[_vm._v(" "+_vm._s(((_vm.event.city) + ", " + (_vm.event.country)))+" ")])])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }