<template>
  <v-card>
    <v-card-text>
      <v-img
        height="100px"
        contain
        :src="img"
      ></v-img>
    </v-card-text>
    <v-card-text class="d-flex mt-5">
      <p class="text-base font-weight-semibold text--primary mb-0">
        {{ getTitle(event) }}
      </p>
    </v-card-text>
    <v-card-text class="d-flex">
      <div
        v-if="event.country"
        class="d-flex align-center mr-8"
      >
        <v-icon
          size="20"
        >
          {{ icons.mdiMapMarkerOutline }}
        </v-icon>
        <div class="ms-2">
          <p class="text--primary mb-0">
            {{ `${event.city}, ${event.country}` }}
          </p>
        </div>
      </div>
      <div
        class="d-flex align-center"
      >
        <v-icon
          size="20"
        >
          {{ icons.mdiClockOutline }}
        </v-icon>
        <div class="ms-2">
          <p class="text--primary mb-0">
            {{ $moment(event.dateFrom).locale($i18n.locale).format('dddd, D MMMM') }}
          </p>
        </div>
      </div>
    </v-card-text>
    <v-card-text>
      <span>
        <v-tooltip
          color="primary"
          max-width="350"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >{{ getDescripcion(event) | truncate(300) }}</span>
          </template>
          <span>{{ getDescripcion(event) }}</span>
        </v-tooltip>
      </span>
    </v-card-text>

    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-text class="text-center">
      <v-row>
        <v-col cols="6">
          <p
            class="text-left mt-2"
            self="bottom"
          >
            {{ $t('landing.evento.from') }} <b>{{ price | currency(currency) }}</b>
          </p>
        </v-col>
        <v-col cols="6">
          <v-btn
            color="primary"
            block
            outlined
          >
            <!--:to="`event/${event.slug}`"-->
            <span>{{ $t('landing.evento.showAct') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiPlus, mdiChevronDown, mdiClockOutline,
  mdiMapMarkerOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    event: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    currencies: {
      type: Array,
    },
  },
  setup() {
    return {
      urlS3: process.env.VUE_APP_URL_S3,

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiClockOutline,
        mdiMapMarkerOutline,
      },
      developerMeetup: [
        { icon: mdiClockOutline, title: 'Tuesday, 24 january, 10:20 - 12:30', subtitle: 'After 1 week' },
        { icon: mdiMapMarkerOutline, title: 'The Rochard NYC', subtitle: '1305 Lexington Ave, New York' },
      ],
    }
  },
  data() {
    return {
      urlS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      imgs: [],
      img: null,
      price: 0,
      currency: '',
    }
  },
  created() {
    if (this.event.prices) {
      if (this.event.prices.length > 0) {
        /* applyBy: "ppp"
        description: "Second"
        moneda: "EUR"
        payOnline: true
        price: "100"
        requered: true
        */
        this.event.prices.forEach(element => {
          if (element.requered) {
            this.price = parseFloat(element.price) + parseFloat(this.price)
            if (this.currency === '') {
              this.currency = this.currencies.filter(e => e.code === element.moneda)[0].symbol
            }
          }
        })
      }
    }
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event1.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event2.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event3.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event4.svg'))
    this.shuffleDeck()
  },
  methods: {
    getTitle(item) {
      if (this.$i18n.locale === 'es') {
        return item.title
      }

      return item[`title_${this.$i18n.locale}`]
    },
    getDescripcion(item) {
      if (this.$i18n.locale === 'es') {
        return item.description
      }

      return item[`description_${this.$i18n.locale}`]
    },
    shuffleDeck() {
      const numbers = [...this.imgs]
      let first
      let second
      let temp
      const count = numbers.length
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        first = Math.floor(Math.random() * count)
        second = Math.floor(Math.random() * count)
        temp = numbers[first]
        numbers[first] = numbers[second]
        numbers[second] = temp
      }
      this.imgs = numbers
      if (this.event.img) {
        this.img = this.urlS3 + this.event.img
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.img = this.imgs[0]
      }
    },
  },
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.punt55Mine {
  font-size: 40px;
}

.punt55MineMov {
  font-size: 28px;
}

.punt32Mine {
  font-size: 20px;
}

.punt32MineMov {
  font-size: 20px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
